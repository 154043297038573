import axios from 'axios';

import { ROUTE_APP_CRAFTER, ROUTE_SCANBAT } from '../constants';
import SessionStoragePath from '../services/SessionStorage.path';

const axiosPublic = axios.create();

axiosPublic.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export const setHeaders = (config, apiScope) => {
  const currentUrl = window.location.href;

  const getRedirectUri = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('redirect_uri');
  };

  const pathService = SessionStoragePath.getService();

  const handleApiScope = () => {
    const redirectUri = getRedirectUri();
    if (currentUrl.includes(ROUTE_APP_CRAFTER)) {
      return 'AC';
    }
    if (
      currentUrl.includes(ROUTE_SCANBAT) ||
      (redirectUri && redirectUri.includes('scanbat')) ||
      (pathService.getPrevPath().includes('scanbat') &&
        pathService.getCurrentPath().includes('scanbat'))
    ) {
      return 'SB';
    }
    return apiScope;
  };

  return {
    ...config,
    headers: {
      ...config.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-scope': handleApiScope(),
    },
  };
};

export const interceptPublicRequests = (apiScope) =>
  axiosPublic.interceptors.request.use(
    (config) => setHeaders(config, apiScope),
    (error) => Promise.reject(error),
  );

export const interceptPublicResponses = ({ isAPIDown, setIsAPIDown }) =>
  axiosPublic.interceptors.response.use(
    (response) => {
      if (isAPIDown) {
        setIsAPIDown(false);
      }
      return response;
    },
    (error) => {
      // Check in response status is 500
      if (error.response.status === 500) {
        // If isAPIDown is false, set to true
        if (!isAPIDown) {
          setIsAPIDown(true);
        }
      }
      return Promise.reject(error);
    },
  );

export default axiosPublic;
